import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";
import { ApiService } from "../api/api.service";

@Injectable({
  providedIn: "root",
})
export class RequestService {
  private urls = {
    requests: "api/requests",
  };

  constructor(private apiService: ApiService) {}

  getRequests(params?: any) {
    return this.apiService.get(this.urls.requests, params);
  }

  getRequest(id: any) {
    return this.apiService.get(`${this.urls.requests}/${id}`);
  }

  saveRequest(data: any) {
    return this.apiService.post(this.urls.requests, data);
  }

  updateRequest(id: any, data: any) {
    return this.apiService.put(`${this.urls.requests}/${id}`, data);
  }

  abandonRequest(id: any, data: any) {
    return this.apiService.post(`${this.urls.requests}/${id}/abandon`, data);
  }

  restoreRequest(id: any) {
    return this.apiService.get(`${this.urls.requests}/${id}/restore`);
  }

  deleteRequest(id: any) {
    return this.apiService.delete(`${this.urls.requests}/${id}`);
  }

  getActiveRequests() {
    return this.apiService
      .get("api/active-requests")
      .pipe(map((count) => count || 0));
  }

  getConvertedRequests(params?: any) {
    return this.apiService.get("api/converted-requests", params);
  }

  getConvertedRequest(id: any) {
    return this.apiService.get(`api/converted-requests/${id}`);
  }

  markAsRead(id: any) {
    return this.apiService.put(`api/readed/${id}`);
  }

  convertToBid(id: any) {
    return this.apiService.put(`${this.urls.requests}/${id}/convert`);
  }
}
