import { DOCUMENT } from "@angular/common";
import { Injectable, Inject, Renderer2, RendererFactory2 } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class ThemeService {
  selectedTheme = "light";
  cssFile!: string;
  style!: HTMLLinkElement;
  private renderer2: Renderer2;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    rendererFactory: RendererFactory2
  ) {
    this.renderer2 = rendererFactory.createRenderer(null, null);
  }

  setTheme() {
    this.cssFile = `${this.selectedTheme}.css`;
    this.removeExisting();
    this.style = this.renderer2.createElement("link") as HTMLLinkElement;

    this.renderer2.setProperty(this.style, "rel", "stylesheet");
    this.renderer2.setProperty(this.style, "href", this.cssFile);
    this.renderer2.setProperty(this.style, "id", "themeCSS");

    this.renderer2.appendChild(this.document.head, this.style);
  }

  removeExisting() {
    const themeHtmlElem = this.document.getElementById("themeCSS");
    if (themeHtmlElem)
      this.renderer2.removeChild(this.document.head, themeHtmlElem);
  }
}
