import { HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ApiService } from "../api/api.service";

@Injectable({
  providedIn: "root",
})
export class NotificationService {
  private urls = {
    notifications: "api/notifications",
  };

  constructor(private apiService: ApiService) {}

  getNotifications(filter?) {
    return this.apiService.get(`${this.urls.notifications}`, filter);
  }

  readNotification(id) {
    const headers = new HttpHeaders({ skipMessage: "true" });
    return this.apiService.put(
      `${this.urls.notifications}/${id}`,
      null,
      headers
    );
  }

  readAll(data: any) {
    return this.apiService.put(`${this.urls.notifications}/all`, data);
  }

  clearAll(filter) {
    return this.apiService.get(`${this.urls.notifications}/clear`, filter);
  }

  deleteNotification(id) {
    const headers = new HttpHeaders({ skipMessage: "true" });
    return this.apiService.delete(`${this.urls.notifications}/${id}`, {
      headers: headers,
    });
  }
}
