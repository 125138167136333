import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AuthGuardService } from "./services/auth/auth-guard.service";

// Import Containers
import { DefaultLayoutComponent } from "./containers";

export const routes: Routes = [
  {
    path: "",
    redirectTo: "dashboard",
    pathMatch: "full",
  },
  {
    path: "",
    children: [
      {
        path: "",
        loadChildren: () =>
          import("./views/auth/auth.module").then((m) => m.AuthModule),
      },
    ],
  },
  {
    path: "",
    children: [
      {
        path: "",
        loadChildren: () =>
          import("./views/pages/pages.module").then((m) => m.PagesModule),
      },
    ],
  },
  {
    path: "",
    component: DefaultLayoutComponent,
    canActivate: [AuthGuardService],
    canActivateChild: [AuthGuardService],
    children: [
      {
        path: "dashboard",
        loadChildren: () =>
          import("./views/dashboard/dashboard.module").then(
            (m) => m.DashboardModule
          ),
        data: {
          title: "Dashboard",
          permission: "See dashboard",
        },
      },
      {
        path: "work-orders",
        loadChildren: () =>
          import("./views/work-orders/work-orders.module").then(
            (m) => m.WorkOrdersModule
          ),
        data: {
          title: "Work Orders",
          permission: "Work orders",
        },
      },
      {
        path: "requests",
        loadChildren: () =>
          import("./views/requests/requests.module").then(
            (m) => m.RequestsModule
          ),
        data: {
          title: "Requests",
          permission: "Requests",
        },
      },
      {
        path: "calendar",
        loadChildren: () =>
          import("./views/calendar/calendar.module").then(
            (m) => m.CalendarModule
          ),
        data: {
          title: "Calendar",
          permission: "Calendar",
        },
      },
      {
        path: "clients",
        loadChildren: () =>
          import("./views/clients/clients.module").then((m) => m.ClientsModule),
        data: {
          title: "Clients",
          permission: "Clients",
        },
      },
      {
        path: "bids",
        loadChildren: () =>
          import("./views/bids/bids.module").then((m) => m.BidsModule),
        data: {
          title: "Estimates",
          permission: "Bids",
        },
      },
      {
        path: "reports",
        loadChildren: () =>
          import("./views/reports/reports.module").then((m) => m.ReportsModule),
        data: {
          title: "Reports",
          permission: "Reports",
        },
      },
      {
        path: "users",
        loadChildren: () =>
          import("./views/users/users.module").then((m) => m.UsersModule),
        data: {
          title: "Users",
          permission: "Users",
        },
      },
      {
        path: "locations",
        loadChildren: () =>
          import("./views/locations/locations.module").then((m) => m.LocationsModule),
        data: {
          title: "Locations",
          permission: "Locations",
        },
      },
      {
        path: "settings",
        loadChildren: () =>
          import("./views/settings/settings.module").then(
            (m) => m.ReportsModule
          ),
        data: {
          title: "Settings",
          permission: "Edit admin company",
        },
      },
      {
        path: "profile",
        loadChildren: () =>
          import("./views/profile/profile.module").then((m) => m.ProfileModule),
        data: {
          title: "Settings",
        },
      },
      {
        path: "notes",
        loadChildren: () =>
          import("./views/notes/notes.module").then((m) => m.NotesModule),
        data: {
          title: "Notes",
        },
      },
      {
        path: "account",
        loadChildren: () =>
          import("./views/account/account.module").then((m) => m.AccountModule),
        data: {
          title: "Account",
          permission: "Account",
        },
      },
      {
        path: "attachments",
        loadChildren: () =>
          import("./views/manage-attch/manage-attch.module").then(
            (m) => m.ManageAttchModule
          ),
        data: {
          title: "Documents",
          permission: "Attachments",
        },
      },
      {
        path: "gps",
        loadChildren: () =>
          import("./views/maps/maps.module").then((m) => m.MapsModule),
        data: {
          title: "GPS Location",
        },
      },
      {
        path: "time-clock",
        loadChildren: () =>
          import("./views/time-clock/time-clock.module").then(
            (m) => m.TimeClockModule
          ),
        data: {
          title: "Time clock",
          permission: "Time clock",
        },
      },
      {
        path: "route-builder",
        loadChildren: () =>
          import("./views/optimized-routes/optimized-routes.module").then(
            (m) => m.OptimizedRoutesModule
          ),
        data: {
          title: "Route Builder",
          permission: "Route Builder",
        },
      },
      {
        path: "leads",
        loadChildren: () =>
          import("./views/leads/leads.module").then((m) => m.LeadsModule),
        data: {
          title: "Leads",
          permission: "Leads",
        },
      },
      {
        path: "opportunity",
        loadChildren: () =>
          import("./views/opportunities/opportunities.module").then(
            (m) => m.OpportunitiesModule
          ),
        data: {
          title: "Opportunities",
          permission: "Opportunities",
        },
      },
    ],
  },
  {
    path: "**",
    redirectTo: "404",
    pathMatch: "full",
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
