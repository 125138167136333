// import { INavData } from "@coreui/angular";

export const navItems = [
  {
    name: "Dashboard",
    url: "/dashboard",
    icon: "icon-home",
    class: "icon-red-100",
    permission: "See dashboard",
  },
  {
    name: "Work Orders",
    url: "/work-orders",
    icon: "icon-notebook",
    class: "icon-red-200",
    permission: "Work orders",
  },
  {
    name: "Requests",
    url: "/requests",
    icon: "icon-book-open",
    class: "icon-red-300",
    permission: "Requests",
  },
  {
    name: "Calendar",
    url: "/calendar",
    icon: "icon-calendar",
    class: "icon-red-400",
    permission: "Calendar",
  },
  {
    name: "Clients",
    url: "/clients",
    icon: "icon-people",
    class: "icon-red-500",
    permission: "Clients",
  },
  {
    name: "Estimates",
    url: "/bids",
    icon: "icon-direction",
    class: "icon-red-600",
    permission: "Bids",
  },
  {
    name: "Locations",
    url: "/locations",
    icon: "cil-building",
    class: "icon-red-600",
    permission: "Locations",
  },
  {
    name: "Reports",
    url: "/reports",
    icon: "icon-chart",
    class: "icon-red-700",
    permission: "Reports",
  },
  {
    name: "Users",
    url: "/users",
    icon: "icon-user",
    class: "icon-red-800",
    permission: "Users",
  },
  {
    name: "Documents",
    url: "/attachments",
    icon: "cil-paperclip",
    class: "icon-red-900",
    permission: "Attachments",
  },
  {
    name: "GPS Location",
    url: "/gps",
    icon: "cil-compass",
    class: "icon-red-100",
    permission: "Tracking",
  },
  {
    name: "Route Builder",
    url: "/route-builder",
    icon: "cil-map",
    class: "icon-red-200",
    permission: "Route Builder",
  },
  {
    name: "Leads",
    url: "/leads",
    icon: "cil-map",
    class: "icon-red-200",
    permission: "Leads",
  },
  {
    name: "Opportunities",
    url: "/opportunity",
    icon: "cil-map",
    class: "icon-red-200",
    permission: "Opportunities",
  },
  {
    name: "Settings",
    url: "/settings",
    icon: "icon-settings",
    class: "icon-red-400",
    permission: "Edit admin company",
  },
  {
    name: "My Account",
    url: "/account",
    icon: "icon-user",
    class: "menu-icon-users",
    permission: "Account",
  },
];
