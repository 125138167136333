import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse,
} from "@angular/common/http";
import { Observable, of, throwError } from "rxjs";
import { tap, catchError } from "rxjs/operators";
import { ToastrService } from "ngx-toastr";
import { Router } from "@angular/router";
import { CookieService } from "ngx-cookie-service";

@Injectable()
export class MessagesInterceptor implements HttpInterceptor {
  constructor(
    public toastrService: ToastrService,
    private router: Router,
    private cookieService: CookieService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (request.headers.has("skipMessage")) {
      const headers = request.headers.delete("skipMessage");
      return next.handle(request.clone({ headers }));
    }

    const headers = request.headers.delete("skipMessage");
    return next.handle(request.clone({ headers })).pipe(
      tap((event) => {
        if (event instanceof HttpResponse) {
          if (
            (event.body && event.status == 200 && request.method == "POST") ||
            request.method == "PUT" ||
            request.method == "DELETE"
          ) {
            this.toastrService.success(event.body.message);
          }
          if (request.method == "GET" && event.body && event.body.message)
            this.toastrService.success(event.body.message);
        }
      }),
      catchError((response: any) => {
        if (response instanceof HttpErrorResponse) {
          try {
            if (response.status == 404) this.router.navigate(["/404"]);
            else if (response.status == 401) {
              this.cookieService.deleteAll();
              this.router.navigate(["login"]);
            } else if (response.status == 423)
              this.router.navigate(["/settings/subscription"]);
            else this.toastrService.error(response.error.message, "Error");
          } catch (e) {
            console.log(e);
          }
        }
        return throwError(() => response);
      })
    );
  }
}
