import { Component, OnDestroy, TemplateRef } from "@angular/core";
import { navItems } from "../../_nav";
import { AuthService } from "@app/services/auth/auth.service";
import { Router } from "@angular/router";
import { ProfileResolver } from "@app/resolvers/profile.resolver";
import { environment } from "../../../environments/environment";
import { RequestService } from "@app/services/request/request.service";
import { ThemeService } from "@app/services/theme/theme.service";
import { UserService } from "@app/services/user/user.service";
import { NotificationService } from "@app/services/notification/notification.service";
import { EchoService } from "@app/services/echo/echo.service";
import { ToastrService } from "ngx-toastr";
import { ColorService } from "@app/services/color/color.service";
import { Observable } from "rxjs";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";

@Component({
  selector: "app-dashboard",
  templateUrl: "./default-layout.component.html",
})
export class DefaultLayoutComponent implements OnDestroy {
  public sidebarMinimized =
    sessionStorage.getItem("hiddenHeader") == "true"
      ? sessionStorage.getItem("hiddenHeader")
      : false;
  public navItems: Observable<any[]>;
  profile: any;
  logo;
  // activeRequests = this.store.pipe(select(fromRoot.getStateData));
  activeRequests;
  hiddenHeader =
    sessionStorage.getItem("hiddenHeader") == "true"
      ? sessionStorage.getItem("hiddenHeader")
      : false;

  notifications = [];
  notCount = 0;
  modalRef: BsModalRef;

  constructor(
    private authService: AuthService,
    private route: Router,
    private profileService: ProfileResolver,
    private requestService: RequestService,
    private userService: UserService,
    private notificationService: NotificationService,
    private echoService: EchoService,
    private toastrService: ToastrService,
    private colorService: ColorService,
    private modalService: BsModalService,
    // private store: Store<{ counter: any }>,
    public themeService: ThemeService
  ) {
    echoService.initConnection();
    echoService.listenNotifications().listen(".server.notification", (data) => {
      this.notifications.push(data);
      this.notCount++;
    });
    echoService.listenStatus().listen(".status.message", (data) => {
      if (data.name == "step-2" || data.name == "step-3")
        toastrService.success("Your import has finished successfully.");
      if (data.name == "qb-cus-success")
        toastrService.success("The information has been sync with Quickbooks.");
      if (data.name == "qb-cus-failed")
        toastrService.error(
          "A problem occurred when trying to create the client in Quickbooks, please contact your administrator.",
          "",
          { timeOut: 5000 }
        );
    });
  }

  ngOnInit() {
    this.profile = this.profileService.profileInfo;
    this.profile.initials =
      this.profile.first_name.charAt(0).toUpperCase() +
      this.profile.last_name.charAt(0).toUpperCase();
    this.logo = this.profile.logo
      ? environment.api.apiUrl + this.profile.logo
      : "assets/img/brand/field-tech-fitness-logo.png";
    this.loadNotications();

    let navClone = JSON.parse(JSON.stringify(navItems));
    if (!this.profileService.isTechnician()) this.getActiveRequets();
    else this.navItems = this.navigationPermissions(navClone);
  }

  ngOnDestroy() {
    location.reload();
  }

  navigationPermissions(navigationItems): any {
    const userPermissions = this.profileService.getPermissions();
    let navWithPermissions = [];

    let index = navigationItems.findIndex((x) => x.name == "Requests");
    navigationItems[index].badge = {
      text: this.activeRequests,
      variant: "primary",
    };

    if (this.profileService.isAdmin()) {
      navigationItems.splice(
        navigationItems.findIndex((x) => x.name == "Account"),
        1
      );
      navigationItems.splice(
        navigationItems.findIndex((x) => x.name == "Leads"),
        1
      );
      navigationItems.splice(
        navigationItems.findIndex((x) => x.name == "Opportunities"),
        1
      );
      if (!this.profileService.adminCompany.storage)
        navigationItems.splice(
          navigationItems.findIndex((x) => x.name == "Documents"),
          1
        );
      if (!this.profileService.adminCompany.tracking)
        navigationItems.splice(
          navigationItems.findIndex((x) => x.name == "GPS Location"),
          1
        );
      if (!this.profileService.adminCompany.route_builder)
        navigationItems.splice(
          navigationItems.findIndex((x) => x.name == "Route Builder"),
          1
        );
      if (!this.profileService.adminCompany.addon_location)
        navigationItems.splice(
          navigationItems.findIndex((x) => x.name == "Locations"),
          1
        );
      return this.menuIconColors(navigationItems);
    }

    if (!this.profileService.adminCompany.addon_location)
      navigationItems.splice(
        navigationItems.findIndex((x) => x.name == "Locations"),
        1
      );

    if (this.profileService.isManager()) {
      if (!this.profileService.adminCompany.tracking)
        navigationItems.splice(
          navigationItems.findIndex((x) => x.name == "GPS Location"),
          1
        );
    }

    if (
      this.profileService.isManager() ||
      this.profileService.isAdmin() ||
      this.profileService.isTechnician()
    ) {
      if (!this.profileService.adminCompany.route_builder)
        navigationItems.splice(
          navigationItems.findIndex((x) => x.name == "Route Builder"),
          1
        );
    }

    navigationItems.forEach((item) => {
      let navItem = { ...item };

      if (navItem.permission) {
        userPermissions.some((permission) => {
          if (permission.name == navItem.permission) {
            navWithPermissions.push(navItem);
            return true;
          }

          return false;
        });
      }
    });
    return this.menuIconColors(navWithPermissions);
  }

  toggleMinimize(e) {
    this.sidebarMinimized = e;
    sessionStorage.setItem(
      "hiddenHeader",
      this.hiddenHeader ? "true" : "false"
    );
  }

  logout() {
    this.authService.logout().subscribe(() => {
      this.route.navigate(["/login"]);
    });
  }

  getActiveRequets() {
    this.requestService.getActiveRequests().subscribe((response) => {
      this.activeRequests = response;
      // this.store.dispatch(fromRoot.retrieveRequestCount(response));
      // this.activeRequests.subscribe((res) => console.log(res));
      this.navItems = this.navigationPermissions(navItems);
    });
  }

  changeTheme(theme) {
    this.themeService.selectedTheme = theme;
    this.themeService.setTheme();
    this.userService
      .changePassword({ theme: this.themeService.selectedTheme })
      .subscribe(() => {
        this.profileService.profileInfo.theme = this.themeService.selectedTheme;
      });
  }

  loadNotications() {
    this.notificationService
      .getNotifications({ user_id: this.profileService.profileInfo.id })
      .subscribe((response) => {
        this.notifications = response;
        this.notifications.forEach((x) => {
          if (!x.readed) this.notCount++;
        });
      });
  }

  redirect(item) {
    if (!item.readed) {
      this.notificationService.readNotification(item.id).subscribe();
      this.notCount--;
    }
    item.readed = true;

    this.route.navigate([
      "/work-orders",
      item.name == "qb-pay-failed" ? item.work_order_id : item.model,
    ]);
  }

  removeNotification(item) {
    this.notificationService.deleteNotification(item.id).subscribe(() => {
      if (!item.readed) this.notCount--;
      this.notifications.splice(
        this.notifications.findIndex((x) => x.id == item.id),
        1
      );
    });
  }

  readAll() {
    this.notificationService
      .readAll({ user_id: this.profileService.profileInfo.id })
      .subscribe(() => {
        this.notifications.forEach((x) => (x.readed = true));
        this.notCount = 0;
      });
  }

  clearAll() {
    this.notificationService
      .clearAll({ user_id: this.profileService.profileInfo.id })
      .subscribe(() => {
        this.notifications = [];
        this.notCount = 0;
      });
  }

  menuIconColors(navItems) {
    let colorGradient = 700;
    navItems.forEach((x) => {
      colorGradient = colorGradient == 400 ? 700 : colorGradient - 100;
      x.class = `icon-${this.profileService.profileInfo.components_color}-${
        colorGradient - 100
      }`;
    });

    return navItems;
  }

  saveColor() {
    this.userService
      .changePassword({
        components_color: this.profileService.profileInfo.components_color,
      })
      .subscribe();
  }

  setColor(e) {
    this.profileService.profileInfo.components_color = e.srcElement.value;
    let navClone = JSON.parse(JSON.stringify(this.navItems));
    this.navItems = this.menuIconColors(navClone);
  }

  redirectToWP(form) {
    if (this.profileService.profileInfo.wp_id || !form)
      window.open("https://fieldtechfitness.com/support/", "_blank");
    else {
      this.userService
        .wpSync(this.profileService.profileInfo.id, {
          password: form.value.password,
        })
        .subscribe((response) => {
          this.profileService.profileInfo.wp_id = response.data;
          window.open("https://fieldtechfitness.com/support/", "_blank");
          this.modalRef.hide();
        });
    }
  }

  checkWP(template: TemplateRef<any>) {
    this.userService
      .checkWP(this.profileService.profileInfo.id)
      .subscribe((response) => {
        if (response.founded) this.redirectToWP(null);
        else this.openModal(template);
      });
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }

  get canGoToTimeClock() {
    return (
      !this.profileService.isClient() &&
      !this.profileService.isAdminClient() &&
      this.profileService.profileInfo.admin_company.time_clock
    );
  }

  get availableColors() {
    return this.colorService.availableColors;
  }
}
