export const environment = {
  production: false,
  api: {
    grant_type: "password",
    apiUrl: "https://stageapi.fieldtechfitnessapp.com/",
    clientId: "2",
    secret: "r5mQS9Lr0hjHNix3sZhu9PKP2Fs93KMksSEiXATe",
    scope: "*",
  },
  firebase: {
    apiKey: "AIzaSyCfyxILPod2vjNyEElPjBLKwJmjJtMADMU",
    authDomain: "field-tech-fitness.firebaseapp.com",
    databaseURL: "https://field-tech-fitness.firebaseio.com",
    projectId: "field-tech-fitness",
    storageBucket: "field-tech-fitness.appspot.com",
    messagingSenderId: "1080301691793",
    appId: "1:1080301691793:web:33fcc1d332a0d374e93030",
    measurementId: "G-9D59QFDN0J",
    cloudFunctionEndpoint:
      "https://us-central1-field-tech-fitness.cloudfunctions.net/notifications",
    email: "developers@cari.net",
    password: "Ftfitness20",
  },
  stripe: {
    publishable: "publishable_key",
  },
  notification: {
    host: "209.126.156.193",
    port: 6001,
    authEndpoint: "https://stageapi.fieldtechfitnessapp.com/broadcasting/auth",
    pusherKey: "5444edaa147a335eb44f",
  },
};
