import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';

@Injectable({
  providedIn: 'root'
})
export class TimeClockService {

  private urls = {
    time: 'api/time-clock',
    check: 'api/check-clock',
  }

  constructor(
    private apiService: ApiService
  ) { }

  getTimeClocks(filter?) {
    return this.apiService.get(this.urls.time, filter);
  }

  getTimeClock(id) {
    return this.apiService.get(`${this.urls.time}/${id}`);
  }

  saveTimeClock(data: any) {
    return this.apiService.post(this.urls.time, data);
  }

  updateTimeClock(id, data: any) {
    return this.apiService.put(`${this.urls.time}/${id}`, data);
  }

  deleteTimeClock(id) {
    return this.apiService.delete(`${this.urls.time}/${id}`);
  }

  checkClock() {
    return this.apiService.get(this.urls.check);
  }

  getIpAddress() {
    return this.apiService.getCall('https://api.ipify.org?format=json')
  }

  nonWorkHours(data: any) {
    return this.apiService.post(`${this.urls.time}/non-work`, data)
  }

  adjustTime(id, data: any) {
    return this.apiService.put(`${this.urls.time}/${id}/adjust`, data)
  }
}
