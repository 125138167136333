import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs";
import { AuthService } from "./auth.service";
import { ProfileResolver } from "@app/resolvers/profile.resolver";

@Injectable({
  providedIn: "root",
})
export class AuthGuardService  {
  constructor(
    private router: Router,
    private authService: AuthService,
    private profileService: ProfileResolver
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    if (!this.authService.isAuthenticated()) {
      this.router.navigate(["/login"]);
    }
    return this.profileService.resolve();
  }

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    if (!this.authService.isAuthenticated()) {
      this.router.navigate(["/login"]);
    }

    // if (
    //   next.routeConfig.path == "dashboard" &&
    //   !this.profileService.canSeeDashboard()
    // ) {

    //   this.router.navigate(["/requests"]);
    //   return true;
    // }

    if (
      next.routeConfig.path == "time-clock" &&
      this.profileService.isAdmin() &&
      !this.profileService.profileInfo.admin_company.time_clock
    ) {
      this.router.navigate(["/401"]);
      return false;
    }

    if (
      next.routeConfig.path == "locations" &&
      this.profileService.isAdmin() &&
      !this.profileService.profileInfo.admin_company.addon_location
    ) {
      this.router.navigate(["/401"]);
      return false;
    }

    if (
      next.routeConfig.path == "quickbooks" &&
      !this.profileService.profileInfo.admin_company.qb
    ) {
      this.router.navigate(["/401"]);
      return false;
    }

    const permissions = next.data.permission;
    let havePermission = false;
    if (permissions) {
      if (this.profileService.isAdmin()) return true;

      const userPermissions = this.profileService.getPermissions();
      userPermissions.some((userPer) => {
        if (userPer.name == permissions) {
          if (permissions == "Time clock" && !this.profileService.profileInfo.admin_company.time_clock) {
            havePermission = false;
          } else if(permissions == "Locations" && !this.profileService.profileInfo.admin_company.addon_location) {
              havePermission = false;
          }
          else {
            havePermission = true;
          }
          return true;
        }
        return false;
      });

      if (!havePermission) this.router.navigate(["/401"]);

      return havePermission;
    }
    return true;
  }
}
