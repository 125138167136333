<app-header
  *ngIf="!hiddenHeader"
  [navbarBrandRouterLink]="['/dashboard']"
  [fixed]="true"
  [navbarBrandFull]="{ src: logo, width: 100, height: 75, alt: 'Company Logo' }"
  [navbarBrandMinimized]="{
    src: 'assets/img/brand/ftf.png',
    width: 30,
    height: 30,
    alt: 'CoreUI Logo'
  }"
  [sidebarToggler]="'lg'"
  [asideMenuToggler]="'lg'"
>
  <i
    class="cil-chevron-top ml-auto mt-auto pointer"
    [tooltip]="!hiddenHeader ? 'Hide header' : ''"
    (click)="hiddenHeader = !hiddenHeader; toggleMinimize(true)"
  >
  </i>
  <ul class="nav navbar-nav ml-auto">
    <li
      class="nav-item dropdown d-md-down-none"
      dropdown
      placement="bottom right"
    >
      <a
        class="nav-link"
        data-toggle="dropdown"
        href="#"
        role="button"
        aria-haspopup="true"
        aria-expanded="false"
        dropdownToggle
        (click)="(false)"
      >
        <i class="icon-bell font-xl text-dark"
          ><span class="badge badge-pill badge-danger" style="font-size: 50%">{{
            notCount
          }}</span></i
        >
      </a>
      <div
        class="dropdown-menu dropdown-menu-right dropdown-menu-lg"
        *dropdownMenu
        aria-labelledby="simple-dropdown"
        style="
          width: auto;
          min-width: 470px;
          max-height: 450px;
          overflow: hidden auto;
        "
      >
        <div class="dropdown-header text-center">
          <strong>You have {{ notifications.length }} notifications</strong>
        </div>
        <a
          *ngFor="let item of notifications"
          (click)="redirect(item)"
          class="dropdown-item pointer"
          [ngClass]="{ readed: item.readed, unreaded: !item.readed }"
        >
          <div class="row">
            <div class="col-10">
              <span> {{ item.timestamp }} - </span>
              <span *ngIf="item.name == 'log'"
                >{{ item.causer }} tagged you on work order #{{
                  item.company_id
                }}</span
              >
              <span *ngIf="item.name == 'work-order'"
                >{{ item.causer }} assign you on work order #{{
                  item.company_id
                }}</span
              >
              <span *ngIf="item.name == 'request'"
                >{{ item.causer }} assigned you on request #{{
                  item.company_id
                }}</span
              >
              <span *ngIf="item.name == 'qb-pay-failed'"
                >The payment #{{ item.company_id }} failed to sync in QB, please
                try again or contact with your admin.</span
              >
            </div>
            <div class="col-2">
              <i
                class="cil-x pointer right"
                (click)="removeNotification(item)"
              ></i>
            </div>
          </div>
        </a>
        <div
          *ngIf="notifications.length > 0"
          class="dropdown-header"
          style="position: initial; bottom: 0px; width: -webkit-fill-available"
        >
          <div class="row">
            <div
              class="col text-center pointer"
              style="border-right: solid 1px"
              (click)="readAll()"
            >
              Mark all as read
            </div>
            <div class="col text-center pointer" (click)="clearAll()">
              Clear all
            </div>
          </div>
        </div>
      </div>
    </li>
    <i
      *ngIf="themeService.selectedTheme == 'light'"
      (click)="changeTheme('dark')"
      class="cil-moon font-xl mr-3 pointer"
    ></i>
    <i
      *ngIf="themeService.selectedTheme == 'dark'"
      (click)="changeTheme('light')"
      class="cil-sun font-xl mr-3 pointer"
    ></i>
    <i
      class="far fa-question-circle font-xl mr-3 pointer"
      (click)="profile.wp_id ? redirectToWP(null) : checkWP(pwdTemplate)"
    ></i>
    <span>{{ profile.full_name }}</span>
    <li class="nav-item dropdown" dropdown placement="bottom right">
      <a
        class="nav-link"
        data-toggle="dropdown"
        href="#"
        role="button"
        aria-haspopup="true"
        aria-expanded="false"
        dropdownToggle
        (click)="(false)"
      >
        <img
          src="assets/img/avatars/9.jpg"
          class="img-avatar"
          alt="admin@bootstrapmaster.com"
        />
        <div class="center-initials">{{ profile.initials }}</div>
      </a>
      <div
        class="dropdown-menu dropdown-menu-right"
        *dropdownMenu
        aria-labelledby="simple-dropdown"
      >
        <a class="dropdown-item" href="#"
          ><i class="fa fa-bell-o"></i><strong>Status:</strong> Online</a
        >
        <a class="dropdown-item" [routerLink]="['/profile']"
          ><i class="fa fa-user"></i> Profile</a
        >
        <a
          *ngIf="canGoToTimeClock"
          class="dropdown-item"
          [routerLink]="['/time-clock']"
          ><i class="cil-clock"></i> Time clock</a
        >
        <a class="dropdown-item pointer" (click)="logout()"
          ><i class="fa fa-lock"></i> Logout</a
        >
      </div>
    </li>
  </ul>
</app-header>
<i
  *ngIf="hiddenHeader"
  class="cil-chevron-bottom ml-auto mr-auto pointer"
  [tooltip]="hiddenHeader ? 'Show header' : ''"
  (click)="hiddenHeader = !hiddenHeader; toggleMinimize(false)"
>
</i>
<div class="app-body">
  <app-sidebar
    #appSidebar
    [fixed]="true"
    [display]="'lg'"
    [minimized]="sidebarMinimized"
    (minimizedChange)="toggleMinimize($event)"
  >
    <app-sidebar-nav [navItems]="navItems"></app-sidebar-nav>
    <app-sidebar-minimizer
      [tooltip]="sidebarMinimized ? 'Expand menu' : 'Minimize menu'"
    ></app-sidebar-minimizer>
  </app-sidebar>
  <main class="main">
    <cui-breadcrumb>
      <li class="breadcrumb-menu d-md-down-none"></li>
    </cui-breadcrumb>
    <div class="container-fluid">
      <router-outlet></router-outlet>
    </div>
  </main>
  <app-aside [fixed]="true" [display]="false">
    <tabset>
      <tab>
        <ng-template tabHeading><i class="icon-settings"></i></ng-template>
        <div class="p-3">
          <h6>Settings</h6>
          <div class="aside-options">
            <div class="clearfix mt-4">
              <small><b>Colors</b></small>
            </div>
            <div class="row">
              <div *ngFor="let item of availableColors" class="col-4">
                <span
                  class="shape-circle mt-2"
                  [style.backgroundColor]="item.color"
                  tooltip="{{ item.name }}"
                >
                  <input
                    type="checkbox"
                    [value]="item.value"
                    (change)="setColor($event)"
                  />
                </span>
              </div>
            </div>
          </div>
          <div class="aside-option">
            <div class="clearfix mt-4 right">
              <button
                type="button"
                class="btn btn-primary"
                (click)="saveColor()"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </tab>
    </tabset>
  </app-aside>
</div>
<ng-template #pwdTemplate>
  <div class="modal-primary">
    <div class="modal-header">
      <h4 class="modal-title">Enter your password</h4>
    </div>
  </div>
  <form #form="ngForm" (ngSubmit)="redirectToWP(form)">
    <div class="modal-body">
      <div class="row">
        <div class="col">
          <label
            >Please enter your password to synchronize your user with the Field
            Tech Fitness support website.</label
          >
          <input
            type="password"
            class="form-control"
            name="password"
            required
            ngModel
          />
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-secondary" (click)="modalRef.hide()">
        Cancel
      </button>
      <button type="submit" class="btn btn-primary">Ok</button>
    </div>
  </form>
</ng-template>
