import { Component } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { interval } from "rxjs";
import { ProfileResolver } from "./resolvers/profile.resolver";
import { TimeClockService } from "./services/time-clock/time-clock.service";
import * as moment from "moment";

@Component({
  // tslint:disable-next-line
  selector: "body",
  templateUrl: "./app.component.html",
})
export class AppComponent {
  title = "field-tech-fitness";

  constructor(
    private timeService: TimeClockService,
    private profileService: ProfileResolver,
    private toastrService: ToastrService
  ) {
    interval(1800000).subscribe(() => {
      this.checkClockOut();
    });
  }

  checkClockOut() {
    if (this.profileService.isLogged()) {
      if (
        (!this.profileService.isClient() ||
          !this.profileService.isAdminClient()) &&
        this.profileService.profileInfo.admin_company.time_clock &&
        this.profileService.profileInfo.admin_company.required_clockin &&
        this.profileService.profileInfo.admin_company.business_hours
      ) {
        this.timeService.checkClock().subscribe((res) => {
          if (res.id) {
            let bHours = JSON.parse(
              this.profileService.profileInfo.admin_company.business_hours
            );
            let to = moment()
              .set("hours", bHours.to.slice(0, 2))
              .set("minutes", bHours.to.slice(3, 5));
            if (to.diff(moment(), "minutes") <= 30)
              this.toastrService.warning("Please remember to clock-out.");
          }
        });
      }
    }
  }
}
