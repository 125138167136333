import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class ColorService {
  availableColors = [
    { name: "Red", value: "red", color: "#ef4444" },
    { name: "Green", value: "green", color: "#34d399" },
    { name: "Blue", value: "blue", color: "#2563eb" },
    { name: "Gray", value: "gray", color: "#6b7280" },
    { name: "Indigo", value: "indigo", color: "#4f46e5" },
    { name: "Purple", value: "purple", color: "#7c3aed" },
  ];

  constructor() {}
}
