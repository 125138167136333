import { Injectable } from "@angular/core";

import { Observable } from "rxjs";
import { UserService } from "@app/services/user/user.service";
import { ThemeService } from "@app/services/theme/theme.service";
import { ToastrService } from "ngx-toastr";
import { TimeClockService } from "@app/services/time-clock/time-clock.service";

@Injectable({ providedIn: "root" })
export class ProfileResolver {
  private profile: any;

  constructor(
    private userService: UserService,
    private themeService: ThemeService,
    private timeService: TimeClockService,
    private toastrService: ToastrService
  ) {}

  getPermissions() {
    return this.profile.permissions;
  }

  isLogged() {
    return this.profile ? true : false;
  }

  isAdmin() {
    return this.profile.profile.roles[0].name == "Admin";
  }

  isClient() {
    return this.profile.profile.roles[0].name == "Client";
  }

  isAdminClient() {
    return this.profile.profile.roles[0].name == "Admin Client";
  }

  isTechnician() {
    return this.profile.profile.roles[0].name == "Technician";
  }

  isManager() {
    return this.profile.profile.roles[0].name == "Manager";
  }

  isSales() {
    return this.profile.profile.roles[0].name == "Sales";
  }

  isWarehouse() {
    return this.profile.profile.roles[0].name == "Warehouse";
  }

  isSalesManager() {
    return this.profile.profile.roles[0].name == "Sales Manager";
  }

  canSeeDashboard() {
    return (
      this.isAdmin() ||
      this.isManager() ||
      this.isTechnician() ||
      this.isWarehouse()
    );
  }

  hasPermission(permission) {
    if (this.isAdmin()) return true;
    return this.getPermissions().find((x) => x.name == permission);
  }

  clearProfile() {
    this.profile = null;
    this.userService.clearProfile();
  }

  get profileInfo() {
    return this.profile.profile;
  }

  get adminCompany() {
    return this.profile.profile.admin_company;
  }

  get color() {
    return this.profile.profile.components_color;
  }

  resolve(): Observable<any> | Promise<any> | any {
    return new Observable<boolean>((observable) => {
      if (this.profile) observable.next(true);
      this.userService.getProfile().subscribe(
        (response) => {
          this.profile = response;
          this.themeService.selectedTheme = this.profile.profile.theme
            ? this.profile.profile.theme
            : "dark";
          // this.themeService.loadTheme();
          if (
            this.profile.profile.admin_company.time_clock &&
            this.profile.profile.admin_company.required_clockin
          ) {
            this.timeService.checkClock().subscribe((res) => {
              if (!res.id)
                this.toastrService.warning("Please remember to clock-in.");
            });
          }
          observable.next(true);
          observable.complete();
        },
        (error) => {
          observable.error(error);
        }
      );
    });
  }
}
