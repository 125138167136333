import { Injectable, Renderer2 } from "@angular/core";
import { Observable } from "rxjs/internal/Observable";
import { CookieService } from "ngx-cookie-service";
import { environment } from "@env/environment";
import { ApiService } from "../api/api.service";
import { HttpHeaders } from "@angular/common/http";
import { ProfileResolver } from "@app/resolvers/profile.resolver";
import { ThemeService } from "../theme/theme.service";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  constructor(
    private apiService: ApiService,
    private cookieService: CookieService,
    private profileService: ProfileResolver,
    private themeService: ThemeService
  ) {}

  public login(username, password) {
    var formData = new FormData();

    formData.append("grand_type", environment.api.grant_type);
    formData.append("client_id", environment.api.clientId);
    formData.append("client_secret", environment.api.secret);
    formData.append("username", username);
    formData.append("password", password);
    formData.append("scope", environment.api.scope);

    const credentials = {
      grant_type: environment.api.grant_type,
      client_id: environment.api.clientId,
      client_secret: environment.api.secret,
      username: username,
      password: password,
      scope: environment.api.scope,
    };

    const headers = new HttpHeaders({ skipMessage: "true" });

    return new Observable((observable) => {
      this.apiService.post("oauth/token", credentials, headers).subscribe(
        (response) => {
          this.cookieService.set("token", response["access_token"]);

          observable.next(response);
          observable.complete();
        },
        (error) => {
          observable.error(error);
        }
      );
    });
  }

  public logout() {
    return new Observable((observable) => {
      this.apiService.delete("api/logout").subscribe(
        () => {
          this.cookieService.deleteAll();
          this.profileService.clearProfile();
          this.themeService.selectedTheme = "dark";
          this.themeService.setTheme();

          observable.next();
          observable.complete();
        },
        (error) => {
          observable.error(error);
        }
      );
    });
  }

  forgotPassword(data: any) {
    return this.apiService.post("api/reset-password", data);
  }

  public getToken() {
    return this.cookieService.get("token");
  }

  public deleteToken() {
    this.cookieService.delete("token");
  }

  public isAuthenticated() {
    if (this.getToken()) return true;
    return false;
  }
}
