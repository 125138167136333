import { Injectable } from "@angular/core";
import { ApiService } from "../api/api.service";
import { Observable } from "rxjs/internal/Observable";
import { HttpHeaders } from "@angular/common/http";

@Injectable({
  providedIn: "root",
})
export class UserService {
  profile: any;
  private urls = {
    technicians: "api/users/technicians",
    users: "api/users",
    managers: "api/users/managers",
    credentials: "api/credentials",
    checkToken: "api/check-token",
    changePassword: "api/change-password",
    usernames: "api/usernames",
    paymentToken: "api/payment-token",
    search: "api/users/search",
  };

  constructor(private apiService: ApiService) {}

  getProfile() {
    return new Observable((observable) => {
      if (this.profile) {
        observable.next(this.profile);
        observable.complete();
      } else {
        this.apiService.get("api/profile").subscribe(
          (response) => {
            this.profile = response;
            observable.next(response);
            observable.complete();
          },
          (error) => {
            observable.error(error);
          }
        );
      }
    });
  }

  clearProfile() {
    this.profile = null;
  }

  getUsers(filter?) {
    return this.apiService.get(this.urls.users, filter);
  }

  getUsernames(filter?) {
    return this.apiService.get(this.urls.usernames, filter);
  }

  getTechnicians() {
    return this.apiService.get(this.urls.technicians);
  }

  getManagers() {
    return this.apiService.get(this.urls.managers);
  }

  getUser(id) {
    return this.apiService.get(`${this.urls.users}/${id}`);
  }

  searchUser(filter?) {
    return this.apiService.get(this.urls.search, filter);
  }

  saveUser(data) {
    return this.apiService.post(this.urls.users, data);
  }

  updateUser(id, data: any) {
    return this.apiService.put(`${this.urls.users}/${id}`, data);
  }

  sendCredentials(id: any) {
    return this.apiService.put(`${this.urls.credentials}/${id}`);
  }

  checkToken(token: any) {
    return this.apiService.get(`${this.urls.checkToken}/${token}`);
  }

  resetPassword(token: any, data: any) {
    return this.apiService.put(`${this.urls.changePassword}/${token}`, data);
  }

  changePassword(data: any) {
    return this.apiService.post(`${this.urls.users}/change-password`, data);
  }

  paymentToken(token: any) {
    const headers = new HttpHeaders({ skipInterceptor: "" });
    return this.apiService.get(
      `${this.urls.paymentToken}/${token}`,
      null,
      headers
    );
  }

  checkWP(id: any) {
    return this.apiService.get(`${this.urls.users}/check/${id}`);
  }

  wpSync(id: any, data: any) {
    return this.apiService.post(`${this.urls.users}/wp/${id}`, data);
  }
}
