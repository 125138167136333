import { Injectable } from "@angular/core";
import { environment } from "@env/environment";
import {
  HttpHeaders,
  HttpClient,
  HttpParams,
  HttpBackend,
  HttpRequest,
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class ApiService {
  private httpBackend: HttpClient;
  constructor(private http: HttpClient, httpBackend: HttpBackend) {
    this.httpBackend = new HttpClient(httpBackend);
  }

  private formatErrors(error: any) {
    return throwError(error.error);
  }

  get(
    path: string,
    params: HttpParams = new HttpParams(),
    headers: HttpHeaders = new HttpHeaders()
  ): Observable<any> {
    return this.http
      .get(`${environment.api.apiUrl}${path}`, { params, headers })
      .pipe(catchError(this.formatErrors));
  }

  put(
    path: string,
    body: object = {},
    headers: HttpHeaders = new HttpHeaders(),
    options?
  ): Observable<any> {
    return this.http
      .put(`${environment.api.apiUrl}${path}`, body, { headers, ...options })
      .pipe(catchError(this.formatErrors));
  }

  post(
    path: string,
    body: object = {},
    headers: HttpHeaders = new HttpHeaders(),
    params: HttpParams = new HttpParams(),
    responseType?: any,
    options?
  ): Observable<any> {
    return this.http
      .post(`${environment.api.apiUrl}${path}`, body, {
        headers,
        params,
        responseType: responseType,
        ...options,
      })
      .pipe(catchError(this.formatErrors));
  }

  delete(path: string, options: object = {}): Observable<any> {
    return this.http
      .delete(`${environment.api.apiUrl}${path}`, options)
      .pipe(catchError(this.formatErrors));
  }

  download(path: string) {
    return this.http.get(`${environment.api.apiUrl}${path}`, {
      responseType: "blob",
    });
  }

  getCall(
    path: string,
    params: HttpParams = new HttpParams(),
    headers: HttpHeaders = new HttpHeaders()
  ): Observable<any> {
    return this.httpBackend
      .get(path, { params, headers })
      .pipe(catchError(this.formatErrors));
  }

  makeRequest(method, path, body?) {
    const request = new HttpRequest(method, path, {
      reportProgress: true,
      responseType: "json",
    });

    return this.http.request(request);
  }
}
